<template>
  <v-layout>
    <v-parallax
      align-center
      justify-center
      fill-height
      :src="this.$store.state.brand.brand.city_splash_login"
      class="fill-brand__image"
    >
      <v-flex xs11 sm6 md5>
        <v-card>
          <v-card-text>
            <v-layout>
              <v-flex xs12 text-xs-center>
                <img
                  :src="this.$store.state.brand.brand.logo"
                  alt="Logo"
                  height="56px"
                />
              </v-flex>
            </v-layout>
            <v-container grid-list-md>
              <v-tabs slot="extension" v-model="tab" grow show-arrows>
                <v-tabs-slider></v-tabs-slider>
                <v-tab>{{ $t('email') }}</v-tab>
                <!-- <v-tab>{{ $t('mobile') }}</v-tab> -->
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <form @submit.prevent="onSubmit">
                    <v-layout wrap>
                      <v-flex xs12>
                        <br />
                        <p class="text-xs-center text-uppercase">
                          {{ $t('email&pass') }}
                        </p>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="email"
                          :label="$t('email')"
                          prepend-icon="email"
                          required
                          data-cy="email"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="password"
                          :label="$t('password')"
                          prepend-icon="lock"
                          type="password"
                          required
                          data-cy="password"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-btn
                          :loading="$wait.any"
                          :disabled="$wait.any"
                          block
                          color="primary"
                          type="submit"
                          data-cy="submit"
                        >
                          {{ $t('login') }}
                        </v-btn>
                      </v-flex>
                      <!-- <v-flex xs6 class="text-xs-left">
                        <nuxt-link to="register">
                          {{ $t('notaccount') }}
                        </nuxt-link>
                      </v-flex> -->
                      <v-flex xs12 class="text-xs-right">
                        <nuxt-link to="password-reset">
                          {{ $t('notpass') }}
                        </nuxt-link>
                      </v-flex>
                      <br />
                    </v-layout>
                  </form>
                </v-tab-item>
                <!-- <v-tab-item>
                  <form v-show="!code.show" @submit.prevent="onSms">
                    <v-layout wrap>
                      <v-flex xs12>
                        <br />
                        <p class="text-xs-center text-uppercase">
                          {{ $t('smscode') }}
                        </p>
                        <client-only>
                          <vue-tel-input
                            v-model="phone"
                            name="phone"
                            :placeholder="$t('phonenumber')"
                            required
                            :valid-characters-only="true"
                            mode="international"
                            @validate="phoneIsValid"
                          ></vue-tel-input>
                        </client-only>
                        <div id="recaptcha-login" class="recaptcha"></div>
                      </v-flex>
                      <v-flex xs12>
                        <v-btn
                          :loading="$wait.any"
                          :disabled="
                            $wait.any ||
                              !reCAPTCHA ||
                              !phone ||
                              !phoneValidation
                          "
                          block
                          color="primary"
                          type="submit"
                        >
                          {{ $t('send') }}
                        </v-btn>
                      </v-flex>
                      <v-flex xs12 class="text-xs-left">
                        <nuxt-link to="register">
                          {{ $t('notaccount') }}
                        </nuxt-link>
                      </v-flex>
                    </v-layout>
                  </form>
                  <form v-show="code.show" @submit.prevent="onCode">
                    <v-layout wrap>
                      <v-flex xs12>
                        <br />
                        <p class="text-xs-center text-uppercase">
                          {{ $t('smscode') }}
                        </p>
                        <v-text-field
                          v-model="code.id"
                          :label="$t('verificationcode')"
                          prepend-icon="vpn_key"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-btn
                          :loading="$wait.any"
                          :disabled="$wait.any"
                          block
                          color="primary"
                          type="submit"
                        >
                          {{ $t('login') }}
                        </v-btn>
                      </v-flex>
                      <v-flex xs12 class="text-xs-right">
                        <a
                          style="text-decoration:underline"
                          @click="code.show = false"
                        >
                          {{ $t('notcode') }}
                        </a>
                      </v-flex>
                    </v-layout>
                  </form>
                </v-tab-item> -->
              </v-tabs-items>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-dialog
        v-model="dialogConfirmingActivitation"
        :full-width="$vuetify.breakpoint.smAndDown"
        lazy
        scrollable
        max-width="500"
      >
        <v-card>
          <v-toolbar dark flat color="primary">
            <v-toolbar-title>Confirmar activación</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialogConfirmingActivitation = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                ¿Esta seguro que desea activar esta cuenta?
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              flat
              color="primary"
              @click="dialogConfirmingActivitation = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              :loading="$wait.any"
              :disabled="$wait.any"
              flat
              type="submit"
              color="primary"
              @click="activateUser"
            >
              Activar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-parallax>
    <v-snackbar
      v-model="snackbar.show"
      :top="true"
      :right="true"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click.native="snackbar.show = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<i18n>
{
  "en": {
    "name": "Name *",
    "lastname": "Last name *",
    "signup": "Sign up",
    "signin": "Sign in",
    "register": "Register",
    "login": "Login *",
    "email": "Email *",
    "mobile": "Mobile",
    "phonenumber": "Phone number *",
    "password": "Password *",
    "privacy": "Notice of Privacy",
    "notaccount": "You do not have an account?",
    "notpass": "Did you forget your password?",
    "account": "Already have an account?",
    "email&pass": "Use your email and password to login",
    "smscode": "Receive a code by sms to log in",
    "join": "Join",
    "tendigits": "To ten digits",
    "send": "Send",
    "notcode": "You did not receive your sms code?",
    "verificationcode": "Verification code *"
  },
  "es": {
    "name": "Nombre *",
    "lastname": "Apellido *",
    "signup": "Crear cuenta",
    "signin": "Iniciar sesión",
    "register": "Registrar",
    "login": "Ingresar",
    "email": "Correo *",
    "mobile": "Celular",
    "phonenumber": "Número de celular *",
    "password": "Contraseña *",
    "privacy": "Aviso de privacidad",
    "notaccount": "¿No tienes cuenta?",
    "notpass": "¿Olvidaste tu contraseña?",
    "account": "¿Ya tienes cuenta?",
    "email&pass": "Usa tu correo y contraseña para ingresar",
    "smscode": "Recibe un código para ingresar",
    "join": "¡Únete",
    "tendigits": "A diez dígitos",
    "send": "Enviar",
    "notcode": "¿No recibiste tu código?",
    "verificationcode": "Código de verificación *"
  }
}
</i18n>

<script>
import _ from 'lodash'
import axios from 'axios'
import async from 'async'
// import firebase from 'firebase/app'
import 'firebase/auth'
// import { VueTelInput } from 'vue-tel-input'
import UserService from '@/services/UserService'

export default {
  // components: {
  //   VueTelInput
  // },
  data() {
    return {
      phoneValidation: false,
      tab: null,
      reCAPTCHA: '',
      email: '',
      password: '',
      phone: '',
      code: {
        show: false,
        id: '',
        verificationId: ''
      },
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 600000,
        text: '',
        right: '',
        top: ''
      },
      dialogConfirmingActivitation: false
    }
  },
  // mounted() {
  //   const self = this
  //   window.recaptchaVerifierLogin = new firebase.auth.RecaptchaVerifier(
  //     'recaptcha-login',
  //     {
  //       callback() {
  //         return new Promise(function(resolve, reject) {
  //           self.reCAPTCHA = true
  //           resolve()
  //         })
  //       },
  //       'expired-callback'() {
  //         return new Promise(function(resolve, reject) {
  //           self.reCAPTCHA = false
  //           reject(new Error('Expired recaptcha'))
  //         })
  //       },
  //       size: this.$vuetify.breakpoint.smAndDown ? 'compact' : 'normal'
  //     }
  //   )
  //   window.recaptchaVerifierLogin.render().then(function(widgetId) {
  //     window.recaptchaWidgetLoginId = widgetId
  //   })
  // },
  methods: {
    onSubmit() {
      this.$wait.start('full')
      this.$auth
        .login({
          type: 'login',
          email: this.email.trim(),
          password: this.password
        })
        .then(() => {
          if (this.$auth.user.type_operator) {
            if (_.includes(this.$auth.user.type_operator, 'receptor')) {
              this.$router.push({ path: '/admin/listener/new' })
            } else if (
              _.includes(this.$auth.user.type_operator, 'callcenter')
            ) {
              this.$router.push({ path: '/admin/listener/attention' })
            } else if (
              _.includes(this.$auth.user.type_operator, 'supervisor')
            ) {
              this.$router.push({ path: '/admin/listener/attention' })
            } else {
              this.$router.push({ path: '/' })
            }
          } else {
            this.$router.push({ path: '/' })
          }
        })
        .catch(err => {
          if (err.code === 'auth/user-disabled') {
            this.dialogConfirmingActivitation = true
          } else if (err.code === 'auth/internal-error') {
            this.snackbar.show = true
            this.snackbar.text =
              'El dominio desde donde intentas acceder no esta permitido.'
            this.snackbar.color = 'error'
          } else {
            this.snackbar.show = true
            this.snackbar.text =
              'No hay registro de usuario correspondiente a este identificador. El usuario puede haber sido eliminado.'
            this.snackbar.color = 'error'
          }
        })
        .finally(() => {
          this.$wait.end('full')
        })
    },
    phoneIsValid($event) {
      this.phoneValidation = $event.isValid
    },
    onSms() {
      this.$wait.start('full')
      this.reCAPTCHA = false
      let user = null
      async.waterfall(
        [
          callback => {
            UserService.getByPhone(this.phone)
              .then(res => {
                user = res
                callback(null, user)
              })
              .catch(err => {
                callback(err)
              })
          },
          (user, callback) => {
            if (!_.isEmpty(user)) {
              this.$store
                .dispatch('user/sendCode', {
                  phone: this.phone,
                  verifier: window.recaptchaVerifierLogin
                })
                .then(res => {
                  this.code.verificationId = res.verificationId
                  this.snackbar.show = true
                  this.snackbar.color = 'success'
                  this.snackbar.text =
                    'Código de verificación enviado con éxito.'
                  this.code.show = true
                  callback(null)
                })
                .catch(err => {
                  callback(err)
                })
            } else {
              // eslint-disable-next-line
              callback({
                message:
                  'Este número no ha sido registrado, por favor registrate.'
              })
            }
          }
        ],
        err => {
          if (err) {
            this.snackbar.color = 'error'
            this.snackbar.show = true
            this.snackbar.text = err.message
          }
          this.$wait.end('full')
        }
      )
    },
    onCode() {
      this.$wait.start('full')
      this.$auth
        .login({
          type: 'login',
          verificationId: this.code.verificationId,
          code: this.code.id.trim()
        })
        .then(() => {
          this.$router.push({ path: '/' })
        })
        .catch(err => {
          if (err.code === 'auth/user-disabled') {
            this.dialogConfirmingActivitation = true
          } else {
            this.snackbar.color = 'error'
            this.snackbar.show = true
            this.snackbar.text = err.message
          }
        })
        .finally(() => {
          this.$wait.end('full')
        })
    },
    activateUser() {
      this.$wait.start('full')
      axios
        .post('/api/activate-user', {
          email: this.email,
          phone: this.phone
        })
        .then(res => {
          this.dialogConfirmingActivitation = false
          this.snackbar.show = true
          this.snackbar.text = 'Cuenta activada con éxito.'
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$wait.end('full')
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.fill-brand__image {
  height 100% !important
  background-repeat no-repeat
  background-position center
  background-size cover
  background-attachment fixed
  width: 100%
}
>>>.v-parallax__content
  display flex
  justify-content center
  align-items center
</style>
